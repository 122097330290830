<template>
  <router-view> </router-view>
  <setting-offcanvas></setting-offcanvas>
</template>

<script>
import SettingOffcanvas from '@/components/setting/SettingOffcanvas.vue'
export default {
  components: { SettingOffcanvas }
}
</script>